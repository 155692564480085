@import "quasar.variables";

html {
  font-size: $base_font_size;

  @media (min-width: #{$breakpoint-md-max}) {
    font-size: #{$base_font_size_lg} !important;
  }

  body {
    background: $body-bg;
    font-family: "Noto Sans", "Roboto", "-apple-system", sans-serif, Arial;;


    #q-app {

      .q-header .q-toolbar {
        height: 4rem;
        padding-left: 2rem;
      }

      .positive {
        color: $positive
      }

      .negative {
        color: $negative
      }

      .q-page-container {

        .q-page {
          min-height: auto !important;
          padding: 0.5rem 0.75rem;

          .q-card {
            padding: 1rem;
            border-radius: 0.3125rem;
            background: #FFF;
            box-shadow: 0 2px 10px 0 rgba(34, 34, 34, 0.05);

            @at-root .btn {
              font-size: 1.125rem;
              font-weight: bold;
            }
          }


          @at-root .container {
            margin: 1rem;
          }

          .title {
            font-size: 1.7rem;
            font-weight: bold;
            @media (max-width: #{$breakpoint-md-max}) {
              font-size: 1.5rem;
            }
          }
        }
      }


    }

    #q-notify {
      .q-notifications__list.q-notifications__list--top.fixed.column.no-wrap.items-end {
        .q-notification {
          margin-top: 3rem;
          margin-right: 2rem;
        }
      }
    }

    .q-tooltip {
      font-size: 1.125rem;

    }

    &.body--dark #q-app .q-page-container .q-page .q-card {
      box-shadow: none;
    }


  }


}

.q-inner-loading {
  background: rgba(255, 255, 255, 0.95)
}
